<template>
  <div class="coupons">
    <ul v-for="(item, index) in coupons" :key="index">
      <li class="box">
        <div class="card">
          <div class="price">￥{{item.value}}</div>
          <div class="content">
            <span class="name">{{item.name}}</span>
          </div>
          <div :class="item.isCheck==true ? 'checked':'noCheck'" @click="check(item)"></div>
        </div>
        <div class="intro">有效期{{item.validDay}}天,自领取之日算起。</div>
      </li>
    </ul>
    <div class="submit">
      <span class="cancel" @click="cancel">暂时不领</span>
      <span class="confirm" @click="confirm">领取</span>
    </div>

  </div>
</template>

<script>
import { ref, onMounted } from 'vue'
import { noGetCoupList,receiveCoupons } from '@/service/user'
import { useRouter } from 'vue-router';
import { showToast  } from "vant";
export default {
  setup() {
    const router = useRouter();
    const storage= localStorage.getItem("token-hyxt");
    const token=JSON.parse(storage);
    const param=ref({});
    const loadData = async () => {
      let res = await noGetCoupList(token.userId)
      if(res.data) {
        coupons.value = res.data.data;
      }
    }
    // 可使用优惠券
    const coupons = ref([]);
    const check = (item) =>{
      if(item.isCheck){
        item.isCheck=false
      }else{
        item.isCheck=true
      }
    }
    const cancel=()=>{
      router.push({path:'/store'});
    }
    const confirm=()=>{
      var flag=false;
      for(var i=0;i<coupons.value.length;i++){
        if(coupons.value[i].isCheck){
          flag=true;
          break;
        }
      }
      if(flag){
        param.value.coupons=JSON.stringify(coupons.value);
        receiveCoupons({coupons:param.value.coupons}).then(res=>{
          if(res.data.status==1){
            showToast("领取成功")
           router.go(-1);//后退+刷新
          }
        })
      }else{
        showToast("请选择领取的优惠券！")
      }


    }



    onMounted(() => {
      loadData()
    })

    return {
      coupons,
      check,
      cancel,
      confirm
    }
  }
}
</script>

<style lang="scss">
  .coupons{
    box-sizing: border-box;
    position: fixed;
    width: 750px;
    height: 1557px;
    background: #ECECEC;
    z-index: 100;
    top: 10px;
    padding-left: 32px;
    padding-right: 32px;
    padding-top: 40px;
    .box{
      width: 686px;
      height: 212px;
      background: #FFFFFF;
      border-radius: 10px;
      border: 1px solid #ECECEC;

      .card{
        width: 686px;
        height: 158px;
        display: flex;
        justify-content: space-between;
        align-items: center;

        .price{
          display: block;
          width: 154px;
          height: 59px;
          font-family: Roboto;
          font-weight: 500;
          font-size: 50px;
          color: #F8483F;
          line-height: 50px;
          margin-left: 40px;
        }

        .content{
          width: 243px;
          height: 160px;
          display: flex;
          flex-direction: column;
          justify-content: space-around;
          .name{
            display: block;
            height: 30px;
            font-family: PingFang SC;
            font-weight: 500;
            font-size: 28px;
            color: #333333;
            line-height: 30px;
          }

        }
        .noCheck{
          width: 30px;
          height: 30px;
          background: #FFFFFF;
          border-radius: 50%;
          border: 1px solid #CCCCCC;
          margin-right: 30px;
        }
        .checked{
          width: 30px;
          height: 30px;
          background: #F8483F;
          border-radius: 50%;
          border: 1px solid #CCCCCC;
          margin-right: 30px;
        }

      }
      .intro{
        box-sizing: border-box;
        width: 686px;
        height: 54px;
        background: #FFF0F0;
        border-radius: 10px;
        padding-left: 39px;

        font-family: PingFang SC;
        font-weight: 500;
        font-size: 24px;
        color: #666666;
        line-height: 54px;


      }
    }
    .submit{
      position: fixed;
      width:686px;
      height:92px;
      bottom: 200px;
      display: flex;
      justify-content: space-evenly;

      .confirm{
        display: inline-block;
        width: 260px;
        height: 76px;
        background: linear-gradient(269deg, #FF7132, #F8483F);
        border-radius: 38px;
        font-family: PingFang SC;
        font-weight: 500;
        font-size: 36px;
        color: #FFFFFF;
        line-height: 76px;
        text-align: center;
      }
      .cancel{
        display: inline-block;
        width: 260px;
        height: 76px;
        background: linear-gradient(269deg, #FF7132, #F8483F);
        border-radius: 38px;
        font-family: PingFang SC;
        font-weight: 500;
        font-size: 36px;
        color: #FFFFFF;
        line-height: 76px;
        text-align: center;
      }

    }






  }
</style>
